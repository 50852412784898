import { render, staticRenderFns } from "./Benne_depense.vue?vue&type=template&id=db17ef90&scoped=true&"
import script from "./Benne_depense.vue?vue&type=script&lang=js&"
export * from "./Benne_depense.vue?vue&type=script&lang=js&"
import style0 from "./Benne_depense.vue?vue&type=style&index=0&id=db17ef90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db17ef90",
  null
  
)

export default component.exports