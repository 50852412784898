<template>
    <div>
      <label for="numberInput">Entrez un nombre :</label>
      <input v-model="inputNumber" type="number" id="numberInput" />
      <p>Résultat en lettres : {{ convertToWords(inputNumber) }}</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TestZone',
    data() {
      return {
        inputNumber: 1,
      };
    },
    methods: {
      convertToWords(number) {
        const units = ['', 'Mille', 'Million'];
        const numbers = ['', 'Un', 'Deux', 'Trois', 'Quatre', 'Cinq', 'Six', 'Sept', 'Huit', 'Neuf', 'Dix', 'Onze', 'Douze', 'Treize', 'Quatorze', 'Quinze', 'Seize', 'Dix-Sept', 'Dix-Huit', 'Dix-Neuf'];
        const tens = ['', '', 'Vingt', 'Trente', 'Quarante', 'Cinquante', 'Soixante', 'Soixante-Dix', 'Quatre-Vingt', 'Quatre-Vingt-Dix'];
  
        function convertGroup(number) {
          const unitsDigit = number % 10;
          const tensDigit = Math.floor((number % 100) / 10);
          const hundredsDigit = Math.floor(number / 100);
  
          let result = '';
  
          if (hundredsDigit > 0) {
            result += `${numbers[hundredsDigit]} Cent `;
          }
  
          if (tensDigit === 1) {
            result += numbers[10 + unitsDigit];
          } else {
            if (tensDigit > 1) {
              result += `${tens[tensDigit]} `;
            }
            if (unitsDigit > 0) {
              result += numbers[unitsDigit];
            }
          }
  
          return result.trim();
        }
  
        if (number === 0) {
          return 'Zéro';
        }
  
        let result = '';
        let groupIndex = 0;
  
        while (number > 0) {
          const group = number % 1000;
  
          if (group > 0) {
            const groupText = convertGroup(group);
            result = `${groupText} ${units[groupIndex]} ${result}`;
          }
  
          number = Math.floor(number / 1000);
          groupIndex++;
        }
  
        return result.trim();
      },
    },
  };
  </script>