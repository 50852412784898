export function chiffresEnLettres(number) {
  const units = ['', 'Mille', 'Million'];
  const numbers = ['', 'Un', 'Deux', 'Trois', 'Quatre', 'Cinq', 'Six', 'Sept', 'Huit', 'Neuf', 'Dix', 'Onze', 'Douze', 'Treize', 'Quatorze', 'Quinze', 'Seize', 'Dix-Sept', 'Dix-Huit', 'Dix-Neuf'];
  const tens = ['', '', 'Vingt', 'Trente', 'Quarante', 'Cinquante', 'Soixante', 'Soixante-Dix', 'Quatre-Vingt', 'Quatre-Vingt-Dix'];

  function convertGroup(group) {
    const unitsDigit = group % 10;
    const tensDigit = Math.floor((group % 100) / 10);
    let hundredsDigit = Math.floor(group / 100);

    let result = '';

    if (hundredsDigit > 0) {
      if(hundredsDigit == 1) {
        hundredsDigit = 0;
      }
      result += `${numbers[hundredsDigit]} Cent `;
    }

    if (tensDigit === 1) {
      result += numbers[10 + unitsDigit];
    } else {
      if (tensDigit > 1) {
        result += `${tens[tensDigit]} `;
      }
      if (unitsDigit > 0) {
        result += numbers[unitsDigit];
      }
    }

    return result.trim();
  }

  function convertDecimal(decimal) {
    const decimalNumbers = [...decimal].map(digit => numbers[parseInt(digit, 10)]);
    return 'Virgule ' + decimalNumbers.join(' ');
  }

  let integerPart = Math.floor(number);
  const decimalPart = number % 1;

  let result = '';

  if (integerPart === 0) {
    result = 'Zéro';
  } else {
    let groupIndex = 0;
    while (integerPart > 0) {
      const group = integerPart % 1000;
      if (group > 0) {
        const groupText = convertGroup(group);
        result = `${groupText} ${units[groupIndex]} ${result}`;
      }
      integerPart = Math.floor(integerPart / 1000);
      groupIndex++;
    }
  }

  if (decimalPart > 0) {
    result += ' ' + convertDecimal(decimalPart.toFixed(2).split('.')[1]);
  }

  return result.trim();
}