import { render, staticRenderFns } from "./Details.vue?vue&type=template&id=68aa2cac&scoped=true&"
import script from "./Details.vue?vue&type=script&lang=js&"
export * from "./Details.vue?vue&type=script&lang=js&"
import style0 from "./Details.vue?vue&type=style&index=0&id=68aa2cac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68aa2cac",
  null
  
)

export default component.exports